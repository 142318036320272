<template>
  <div class="wrapper">
    <side-bar>
      <template slot="links">
        <a class="nav-link ml-1" href="https://ummy.lamnic.com/readme">
          <p class="float-left mr-3 h3">💁‍♀️</p>
          <p class="pt-1 h4">使い方</p>
        </a>
        <a class="nav-link ml-1" target="_blank" href="https://www.youtube.com/channel/UCBs7L_vsWJCI86uovPtgzXg">
          <p class="float-left mr-3 h3">📺</p>
          <p class="pt-1 h4">Youtube</p>
        </a>
        <a class="nav-link ml-1" target="_blank" href="https://twitter.com/keiba_uma_ai">
          <i class="fab fa-twitter"></i>
          <p class="pt-1 h4">Twitter</p>
        </a>
        <!-- <a class="nav-link ml-1" href="https://ummy.lamnic.com/history">
          <p class="float-left mr-3 h3">⏰</p>
          <p class="pt-1 h4">過去の予想</p>
        </a> -->
        <div class="dropdown-divider"></div>
        <!-- <sidebar-link to="/notifications" :name="$t('sidebar.notifications')" icon="🏇"/>
        <sidebar-link to="/icons" :name="$t('sidebar.icons')" icon="🏇"/> -->
        <p class="float-left mr-3 h3">🏇</p>
        <p class="pt-1 h4">{{kaisai_nen}}年{{kaisai_tsuki}}月{{kaisai_bi}}日</p>
        <!-- <sidebar-link :to="`/result`" :name="`予想結果(月曜更新)`" icon="🎯"/> -->
        <div v-for="item in schedules" :key="item.query">
          <div v-show="item.count==0" class="dropdown-divider"></div>
          <sidebar-link v-if="!item.is_taishogai" :to="`../../../../${item.path}`" :name="`${item.name}予想`" icon="🐴"/>
        </div>
        <div>&nbsp;</div>
        <div>&nbsp;</div>
        <div>&nbsp;</div>
        <div>&nbsp;</div>
        <!-- <sidebar-link to="/dashboard" :name="$t('sidebar.dashboard')" icon="🏇"/>
        <sidebar-link to="/maps" :name="$t('sidebar.maps')" icon="🏇"/>
        <sidebar-link to="/profile" :name="$t('sidebar.userProfile')" icon="🏇"/>
        <sidebar-link to="/table-list" :name="$t('sidebar.tableList')" icon="🏇"/>
        <sidebar-link to="/typography" :name="$t('sidebar.typography')" icon="🏇"/>
        <sidebar-link to="/dashboard?enableRTL=true" :name="$t('sidebar.rtlSupport')" icon="🏇"/> -->
      </template>
    </side-bar>
    <div class="main-panel">
      <top-navbar></top-navbar>
      <dashboard-content @click.native="toggleSidebar">

      </dashboard-content>

      <content-footer></content-footer>
    </div>
  </div>
</template>
<style lang="scss">
</style>
<script>
import TopNavbar from "./TopNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import DashboardContent from "./Content.vue";
// import MobileMenu from "./MobileMenu";
export default {
  components: {
    TopNavbar,
    ContentFooter,
    DashboardContent,
    // MobileMenu
  },
  data() {
    return {
      schedules: []
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
    init() {
      this.schedules = JSON.parse(JSON.stringify(require(`@/data/schedule.json`)))
      this.kaisai_nen = this.schedules[0].kaisai_nen;
      this.kaisai_tsuki = this.schedules[0].kaisai_tsuki;
      this.kaisai_bi = this.schedules[0].kaisai_bi;
      this.schedules = this.schedules.sort((d1,d2) => {
        return d1.path > d2.path ? 1 : -1;
      });
    }
  }
};
</script>

