import DashboardLayout from "@/layout/dashboard/DashboardLayout.vue";
// GeneralViews
import NotFound from "@/pages/Notfound.vue";
// import Youtube from "@/pages/Youtube.vue";
import Index from "@/pages/Index.vue";
import Readme from "@/pages/Readme.vue";
import Result from "@/pages/Result.vue";
import History from "@/pages/History.vue";
import List from "@/pages/List.vue";

// Admin pages
const Dashboard = () => import(/* webpackChunkName: "dashboard" */"@/pages/Dashboard.vue");

const routes = [
  {
    path: "/",
    component: DashboardLayout,
    children: [
      {
        path: "/",
        name: "ユーミィの競馬予想AI・開発室トップ",
        component: Index
      },
      {
        path: "/nodata/nodata/1",
        name: "ユーミィの競馬予想AI・開発室",
        component: Index
      },
      {
        path: "/:keibajo_name/:race_no",
        name: "ユーミィの競馬予想AI・開発室レース詳細",
        component: Dashboard
      },
      {
        path: "/readme",
        name: "はじめに",
        component: Readme
      },{
        path: "/result",
        name: "結果",
        component: Result
      }
      ,{
        path: "/list",
        name: "レース一覧",
        component: List
      },

      {
        path: "/history",
        name: "過去の予想",
        component: History
      }
    ]
  },
  { path: "*", component: NotFound },
];

/**
 * Asynchronously load view (Webpack Lazy loading compatible)
 * The specified component must be inside the Views folder
 * @param  {string} name  the filename (basename) of the view to load.
function view(name) {
   var res= require('../components/Dashboard/Views/' + name + '.vue');
   return res;
};**/

export default routes;
