<template>
  <card type="user">
    <p class="card-text">
    </p>
    <div class="author">
      <div class="block block-one"></div>
      <div class="block block-two"></div>
      <div class="block block-three"></div>
      <div class="block block-four"></div>
      <!-- <img class="avatar" src="img/yumii_icon.jpg" alt="..."> -->
      <h1 class="title">{{user.fullName}}</h1>
      <p class="description">
        {{user.title}}
      </p>
    </div>
    <p></p>
      <p class="h4 mb-4" align="center"><b>ここでは、機械学習AI💻を用いて</b></p>
      <p class="h4 mb-4" align="center"><b>中央競馬🏇のレース予想を、掲載しています。</b></p>
      <p class="h4 mb-4" align="center"><b>はじめての方は「使い方📃」をご一読ください。</b></p>
      <p class="h4 mb-4" align="center"><b>予想は左のメニューの「🏇今日のレース」から選択して閲覧することができます。</b></p>
    <div slot="footer" class="button-container">
      <!-- <base-button icon round class="btn-facebook">
        <i class="fab fa-facebook"></i>
      </base-button> -->
      <!-- <base-button icon round class="btn-twitter">
        <i class="fab fa-twitter"></i>
      </base-button> -->
      <!-- <base-button icon round class="btn-google">
        <i class="fab fa-google-plus"></i>
      </base-button> -->
    </div>
  </card>
</template>
<script>
  export default {
    props: {
      user: {
        type: Object,
        default: () => {
          return {};
        }
      }
    }
  }
</script>
<style>
</style>
