<template>
  <div>
    <div class="row">
      <div class="col-12">
        <card type="chart">
          <template slot="header">
            <div class="row">
              <div class="col-sm-6" :class="isRTL ? 'text-left' : 'text-left'">
                <h5 class="card-category"></h5>
                <h2 class="card-title">
                  {{kaisai_nen}}年{{kaisai_tsuki}}月{{kaisai_bi}}日の結果
                </h2>
              </div>
              <div class="col-sm-6">
              </div>
            </div>
          </template>
        </card>
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <card type="chart">
          <template slot="header">
            <div class="row">
              <div class="col-sm-12">
                &nbsp;&nbsp;1推し：◎<br/>
                &nbsp;&nbsp;2推し：〇<br/>
                &nbsp;&nbsp;3推し：▲<br/>
                &nbsp;&nbsp;4推し：△<br/>
                &nbsp;&nbsp;5推し：☆<br/>
                &nbsp;&nbsp;人気は単勝の最終人気。<br/>
              </div>
              <div class="col-sm-12">
                <span style="color:#00ffff;">青字</span>は5人気以下で推薦して、入着した馬。<br/>
                <span style="color:red;">赤字</span>は5人気以内で入着したが、推薦していなかった馬。<br/>
              </div>
              <div class="col-sm-12" style="font-weight:bold;font-family:monospace;" v-html="result">
                {{result}}
              </div>
            </div>
          </template>
          <div class="col-12">
          </div>
        </card>
      </div>
      <v-row justify="start" align-content="end">
        <v-col>
          <img height="400px" src="/img/tachie-smile.png"/>
          <div>&nbsp;</div>
          <div>&nbsp;</div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<script>

  export default {
    components: {
    },
    data() {
      return {
        result: ""
      }
    },
    computed: {
      enableRTL() {
        return this.$route.query.enableRTL;
      },
      isRTL() {
        return this.$rtl.isRTL;
      }
    },
    mounted() {
      let schedule = require(`@/data/schedule.json`);
      schedule = schedule.sort((d1,d2) => {
        return d1.path > d2.path ? 1 : -1;
      });
      this.kaisai_nen = schedule[0].kaisai_nen;
      this.kaisai_tsuki = schedule[0].kaisai_tsuki;
      this.kaisai_bi = schedule[0].kaisai_bi;
      document.title = `${this.kaisai_nen}年${this.kaisai_tsuki}月${this.kaisai_bi}日のAI予想結果 - 🏇ユーミィの競馬AI予想🏇`;
      let text= ``;
      let currentPlace = "";
      const chimei = {
          "nakayama": "中山",
          "tokyo": "東京",
          "fukushima": "福島",
          "hanshin": "阪神",
          "niigata": "新潟",
          "chukyo": "中京",
          "kyoto": "京都",
          "kawasaki":"川崎",
          "ooi":"大井",
          "monbetsu":"門別",
          "hakodate": "函館",
          "kokura": "小倉",
          "sapporo": "札幌",
          "urawa": "浦和",
          "funabashi": "船橋",
          "saga":"佐賀",
          "sonoda":"園田",
          "himeji":"姫路",
          "kasamatsu":"笠松",
          "morioka":"盛岡",
          "mizusawa":"水沢",
          "kanazawa":"金沢",
          "nagoya":"名古屋",
          "kouchi":"高知"
      }

      for(const race of schedule) {
        if(race.is_taishogai) {
          continue;
        }
        const path = race.path.split("/");
        // if ( path[0] === "fukushima") {
        //   continue;
        // }
        let predictData = require(`@/data/${path[0]}-${Number(path[1])}/predict.json`);
        if(currentPlace !== path[0]) {
          text+=`<br/>${chimei[path[0]]}<br/>`
          currentPlace = path[0];
        }
        predictData = predictData.sort((d1,d2) => {
          return d2.score - d1.score;
        });
        predictData.map(data => {
          data.mark="―"
          return data;
        })
        predictData[0].mark = "◎"
        predictData[1].mark = "〇"
        predictData[2].mark = "▲"
        predictData[3].mark = "△"
        predictData[4].mark = "☆"

        const first = predictData.find(data => Number(data.chakujun) === 1);
        const second = predictData.find(data => Number(data.chakujun) === 2);
        const third = predictData.find(data => Number(data.chakujun) === 3);
        let firstMark = first.mark;
        let secondMark = second.mark;
        let thirdMark = third.mark;
        if (firstMark !== '―' && first.ninki > 5) {
          firstMark = `<span style="font-weight:bold;color:#00ffff;">${firstMark}</span>`
        }
        if (firstMark === '―' && first.ninki <= 5) {
          firstMark = `<span style="font-weight:bold;color:red ;">${firstMark}</span>`
        }
        if (secondMark !== '―' && second.ninki > 5) {
          secondMark = `<span style="font-weight:bold;color:#00ffff;">${secondMark}</span>`
        }
        if (secondMark === '―' && second.ninki <= 5) {
          secondMark = `<span style="font-weight:bold;color:red ;">${secondMark}</span>`
        }
        if (thirdMark !== '―' && third.ninki > 5) {
          thirdMark = `<span style="font-weight:bold;color:#00ffff;">${thirdMark}</span>`
        }
        if (thirdMark === '―' && third.ninki <= 5) {
          thirdMark = `<span style="font-weight:bold;color:red ;">${thirdMark}</span>`
        }

        text += `${path[1].padStart(2, '0')}R ${firstMark},${secondMark},${thirdMark} (${Number(first.ninki)}人気,${Number(second.ninki)}人気,${Number(third.ninki)}人気)</br>`
      }
      this.result = text;
    }
  };
</script>
<style>
</style>
