<template>
  <div>
    <div class="row">
      <div class="col-12">
        <card type="chart">
          <template slot="header">
            <div class="row">
              <div class="col-sm-6" :class="isRTL ? 'text-left' : 'text-left'">
                <h5 class="card-category"></h5>
                <h2 class="card-title">
                  はじめに
                </h2>
              </div>
              <div class="col-sm-6">
              </div>
            </div>
          </template>
        </card>
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <card type="chart">
          <template slot="header">
            <div class="row">
              <div class="col-sm-12">
                <h2 class="card-title">00. 予想と馬券の購入について。</h2>
                <div class="mb-4">
                  このページはAIによる予想を掲載するもので、<span style="color:red;">着順を約束・保障するものではありません。</span><br/>
                  馬券の購入は<span style="color:red;">自己責任</span>でお願いいたします。
                  予想に起因する損害等に対する責任については<span style="color:red;">一切負いません。</span>
                </div>
                <h2 class="card-title">01. このページについて。</h2>
                <div class="mb-4">
                  このページはバーチャルVTuber「ユーミィ」が作成した、競馬予想AIによる予想結果を掲載するウェブサイトです。<br/>
                  ユーミィは一応ITエンジニアではありますが、機械学習に関しては素人です。<br/>
                </div>
                <br/>
                <br/>
                <h2 class="card-title">02. 予想の種類について</h2>
                <div class="mb-4">
                  このウェブサイトでは、大きく分けて3つの観点から予想を掲載します。<br/>
                  <br/>
                  １つめが「ユーミィの直前AI予想」です。<br/>
                  これは、過去のデータや、パドックの状態、直前の馬体重などを含めてAIが算出した最終見解です。<br/>
                  直前情報を必要とするため、およそレースの10～20分前に更新されます。
                  <br/><br/>
                  2つめは、「ユーミィの前日AI予想」です。<br/>
                  直前にしか入手できない情報(パドックや、馬体重、馬場状態、天候など)を除いたAI予想です。<br/>
                  馬体重は前走から変化していない前提で計算し、天候、馬場状態は推測でAIが予想します。<br/>
                  レースの前日～当日の朝までに更新されます。<br/>
                  <br/><br/>
                  3つめは、出走馬と騎手の「指数」です。<br/>
                  過去のデータを元に、項目ごとの強さを数値化します。<br/>
                </div>
                <br/>
                <br/>
                <h2 class="card-title">03. 予想の見方</h2>
                <div class="mb-4">
                  予想は以下のような形で提供されます。<br/>
                  <br/>
                  <img src="img/yosou_example.png"/><br/>
                  <br/>
                  AIは、対象の馬がそのレースで、１～３着に入るかどうかを予想します。<br/>
                  １～３着に入る可能性が高ければ高いほど「スコア」の値が高くなります。<br/>
                  確率ではないので注意してください。<br/>
                  最も低いスコアは0で、最も高いスコアは100です。<br/>
                  <br/>
                  傾向として、スコアが高く、他の出走馬とも高いスコア差をつけた馬が１～３着に入りやすい傾向にあります。<br/>
                  <br/>
                  「予想グループ」はAIが、その対象の馬が何着に入るか予想したものです。<br/>
                  ・「01_軸候補」は、AIが対象の馬は「1～3着以内に入る可能性が高いだろう」と予想しています。<br/>
                  ・「02_相手候補」は、AIが対象の馬は「4～6着以内に入る可能性が高いだろう」と予想しています。<br/>
                  ・「02_穴候補」は、AIが対象の馬は「7着以降だろう」と予想しています。<br/>
                  <br/>
                  場合によっては「01_軸候補」が1頭も居ないこともあります。他のグループについても同様です。<br/>
                </div>
                <br/>
                <br/>
                <h2 class="card-title">04. 質問・連絡先について</h2>
                <div class="mb-4">
                  以下のどちらかにTwitterのDMまたはリプライよりご連絡ください。<br/>
                  <br/>
                  ・masachaco<br/>
                  <a href="https://twitter.com/masachaco" target="_blank"> https://twitter.com/masachaco</a><br/>
                  <br/>
                  ・ユーミィ<br/>
                  <a href="https://twitter.com/keiba_uma_ai" target="_blank">https://twitter.com/keiba_uma_ai</a><br/>
                </div>
              </div>
            </div>
          </template>
          <div class="col-12">
          </div>
        </card>
      </div>
      <v-row justify="start" align-content="end">
        <v-col>
          <img height="400px" src="img/tachie-smile.png"/>
          <div>&nbsp;</div>
          <div>&nbsp;</div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<script>

  export default {
    components: {
    },
    data() {
      return {
      }
    },
    computed: {
      enableRTL() {
        return this.$route.query.enableRTL;
      },
      isRTL() {
        return this.$rtl.isRTL;
      }
    },
    mounted() {
    }
  };
</script>
<style>
</style>
