<template>
  <div>
    <div class="row">
      <div class="col-12">
        <card type="chart">
          <template slot="header">
            <div class="row">
              <div class="col-sm-6" :class="isRTL ? 'text-left' : 'text-left'">
                <h5 class="card-category"></h5>
                <h2 class="card-title">
                  過去の予想
                </h2>
              </div>
              <div class="col-sm-6">
              </div>
            </div>
          </template>
        </card>
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <card type="chart">
          <template slot="header">
            <div class="row">
              <div class="col-sm-12 mt-3 ml-3">
                  <ul>
                      <li>
                        <a style="color:white;" href="http://ummy.lamnic.com/20220619/list"> 2022年06月19日</a>
                      </li>
                      <li>
                        <a style="color:white;" href="http://ummy.lamnic.com/20220619/list"> 2022年06月19日</a>
                      </li>
                      <li>
                        <a style="color:white;" href="http://ummy.lamnic.com/20220618/list"> 2022年06月18日</a>
                      </li>
                      <li>
                        <a style="color:white;" href="http://ummy.lamnic.com/20220614/list"> 2022年06月14日</a>
                      </li>
                      <li>
                        <a style="color:white;" href="http://ummy.lamnic.com/20220613/list"> 2022年06月13日</a>
                      </li>
                      <li>
                        <a style="color:white;" href="http://ummy.lamnic.com/20220612/list"> 2022年06月12日</a>
                      </li>
                      <li>
                        <a style="color:white;" href="http://ummy.lamnic.com/20220611/list"> 2022年06月11日</a>
                      </li>
                      <li>
                        <a style="color:white;" href="http://ummy.lamnic.com/20220605/list"> 2022年06月05日</a>
                      </li>
                      <li>
                        <a style="color:white;" href="http://ummy.lamnic.com/20220604/list"> 2022年06月04日</a>
                      </li>
                      <li>
                        <a style="color:white;" href="http://ummy.lamnic.com/20220529/list"> 2022年05月29日</a>
                      </li>
                      <li>
                        <a style="color:white;" href="http://ummy.lamnic.com/20220528/list"> 2022年05月28日</a>
                      </li>
                  </ul>
              </div>
            </div>
          </template>
          <div class="col-12">
          </div>
        </card>
      </div>
      <v-row justify="start" align-content="end">
        <v-col>
          <img height="400px" src="/img/tachie-smile.png"/>
          <div>&nbsp;</div>
          <div>&nbsp;</div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<script>

  export default {
    components: {
    },
    data() {
      return {
        result: ""
      }
    },
    computed: {
      enableRTL() {
        return this.$route.query.enableRTL;
      },
      isRTL() {
        return this.$rtl.isRTL;
      }
    },
    mounted() {
      const schedule = require(`@/data/schedule.json`);
      let text= ``;
      let currentPlace = "";
      const chimei = {
        "niigata":"新潟",
        "tokyo":"東京",
        "chukyo":"中京"
      }

      for(const race of schedule) {
        if(race.is_taishogai) {
          continue;
        }
        const path = race.path.split("/");
        let predictData = require(`@/data/${path[0]}-${Number(path[1])}/predict.json`);
        if(currentPlace !== path[0]) {
          text+=`<br/>${chimei[path[0]]}<br/>`
          currentPlace = path[0];
        }
        predictData = predictData.sort((d1,d2) => {
          return d2.score - d1.score;
        });
        predictData.map(data => {
          data.mark="―"
          return data;
        })
        predictData[0].mark = "◎"
        predictData[1].mark = "〇"
        predictData[2].mark = "▲"
        predictData[3].mark = "△"
        predictData[4].mark = "☆"

        const first = predictData.find(data => Number(data.chakujun) === 1);
        const second = predictData.find(data => Number(data.chakujun) === 2);
        const third = predictData.find(data => Number(data.chakujun) === 3);
        let firstMark = first.mark;
        let secondMark = second.mark;
        let thirdMark = third.mark; 
        if (firstMark !== '―' && first.ninki > 5) {
          firstMark = `<span style="font-weight:bold;color:#00ffff;">${firstMark}</span>`
        }
        if (firstMark === '―' && first.ninki <= 5) {
          firstMark = `<span style="font-weight:bold;color:red ;">${firstMark}</span>`
        }
        if (secondMark !== '―' && second.ninki > 5) {
          secondMark = `<span style="font-weight:bold;color:#00ffff;">${secondMark}</span>`
        }
        if (secondMark === '―' && second.ninki <= 5) {
          secondMark = `<span style="font-weight:bold;color:red ;">${secondMark}</span>`
        }
        if (thirdMark !== '―' && third.ninki > 5) {
          thirdMark = `<span style="font-weight:bold;color:#00ffff;">${thirdMark}</span>`
        }
        if (thirdMark === '―' && third.ninki <= 5) {
          thirdMark = `<span style="font-weight:bold;color:red ;">${thirdMark}</span>`
        }
        
        text += `${path[1].padStart(2, '0')}R ${firstMark},${secondMark},${thirdMark} (${first.ninki}人気,${second.ninki}人気,${third.ninki}人気)</br>`
        document.title = `過去の予想 - 🏇ユーミィの競馬AI予想🏇`;
      }
      this.result = text;
    }
  };
</script>
<style>
</style>
