<template>
  <div>
    <div class="row">
      <div class="col-12">
        <card type="chart">
          <template slot="header">
            <div class="row">
              <div class="col-sm-6" :class="isRTL ? 'text-left' : 'text-left'">
                <h5 class="card-category"></h5>
                <h2 class="card-title">
                  {{kaisai_nen}}年{{kaisai_tsuki}}月{{kaisai_bi}}日のレース一覧
                </h2>
              </div>
              <div class="col-sm-6">
              </div>
            </div>
          </template>
        </card>
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <card type="chart">
          <template slot="header">
            <div class="row">
              <div class="col-sm-12">
                <ul v-for="item in schedules" :key="item.query">
                    <li>
                      <a :href="`../${item.path}`"> 🐴 {{item.name}}</a>
                      <div v-show="item.count==11" class="dropdown-divider"></div>
                    </li>
                </ul>
                <!-- <div v-for="item in schedules" :key="item.query">
                  <sidebar-link v-if="!item.is_taishogai" :to="`../../../${item.path}`" :name="item.name" icon="🐴"/>
                  <div v-show="item.count==11" class="dropdown-divider"></div>
                </div> -->
              </div>
            </div>
          </template>
          <div class="col-12">
          </div>
        </card>
      </div>
      <v-row justify="start" align-content="end">
        <v-col>
          <img height="400px" src="/img/tachie-smile.png"/>
          <div>&nbsp;</div>
          <div>&nbsp;</div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
  export default {
    components: {
    },
    data() {
      return {
        schedules: []
      }
    },
    computed: {
    },
    watch:{
    },
    mounted() {
      this.schedules = JSON.parse(JSON.stringify(require(`@/data/schedule.json`)))
      this.kaisai_nen = this.schedules[0].kaisai_nen;
      this.kaisai_tsuki = this.schedules[0].kaisai_tsuki;
      this.kaisai_bi = this.schedules[0].kaisai_bi;
      this.schedules = JSON.parse(JSON.stringify(require(`@/data/schedule.json`)))
      this.schedules = this.schedules.sort((d1,d2) => {
        return d1.path > d2.path ? 1 : -1;
      });
      document.title = `${this.kaisai_nen}年${this.kaisai_tsuki}月${this.kaisai_bi}日の予想対象レース一覧 - 🏇ユーミィの競馬AI予想🏇`;
    }
  };
</script>
<style>
</style>
