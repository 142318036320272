<template>
  <div>
  </div>
</template>
<script>

  export default {
    components: {
    },
    data() {
      return {
      }
    },
    computed: {
    },
    watch:{
    },
    mounted() {
      const schedule = JSON.parse(JSON.stringify(require(`@/data/schedule.json`)))
      document.location = `http://ummy.lamnic.com/`
    }
  };
</script>
<style>
</style>
