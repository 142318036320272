<template>
  <div class="row">
    <div class="col-md-3">
    </div>
    <div class="col-md-6">
      <user-card :user="user"></user-card>
    </div>
    <div class="col-md-3">
      <img src="img/tachie-normal.png"/>
    </div>
  </div>
</template>
<script>
  import UserCard from './Profile/UserCard'
  export default {
    components: {
      UserCard
    },
    data() {
      return {
        user: {
          fullName: '🏇ユーミィの競馬予想AI・開発室🏇',
          title: '',
          description: `ユーミィの競馬予想AI・開発室へようこそ！ここは`,
        }
      }
    },
    computed: {
    },
    watch:{
    },
    mounted() {
    }
  };
</script>
<style>
</style>
